import * as React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"
import FaqPage from "src/components/common/faqPage";

const Faq0003 = () => {
  const {t} = useTranslation();
  return (
    <FaqPage title={t("faq.noReplay.question")}>
      <p>{t("faq.noReplay.answer")}</p>
    </FaqPage>
  );
}

export default Faq0003

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
